import React, { FC } from "react";
import Helmet from "react-helmet";
import { SeoFragment } from "../../graphql-types";

export const SEO: FC<SeoFragment> = props => {
  const { title, metaDesc } = props;

  return (
    <Helmet title={title || undefined}>
      <script>
        {`
        console.log(\`%c ______
< hooi >
 ------
        \\\\   ^__^
         \\\\  (oo)\\\\_______
            (__)\\\\       )\\\\/\\\\
                ||----w |
                ||     ||\`, "font-family:monospace; color: hotpink; font-size: 1.5rem;")
                
        document.querySelectorAll('a[href^="#"]').forEach(anchor => {
        anchor.addEventListener('click', function (e) {
          e.preventDefault();
          
          document.querySelector(this.getAttribute('href')).scrollIntoView({
            behavior: 'smooth'
          });
        });
      });`}
      </script>

      {metaDesc && <meta name="description" content={metaDesc} />}
    </Helmet>
  );
};
