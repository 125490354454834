import React, { FC, useRef, CSSProperties, useContext } from "react";
import { WavySection } from "./wavy-section";
import { colors, ySpaceRem, xSpaceRem, fontStacks } from "../global-style";
import { Center } from "./center";
import Img, { FluidObject } from "gatsby-image";
import { LayoutContext } from "./layout";
import { StaticQuery, graphql, Link } from "gatsby";
import { FooterQuery } from "../../graphql-types";

export const Footer: FC<{
  style?: CSSProperties;
  backgroundColor?: string;
}> = props => {
  const { style, backgroundColor } = props;

  const { waveClearance, remInPx } = useContext(LayoutContext);

  const stormWrapperEl = useRef<HTMLDivElement>(null);
  const stormImageWrapperEl = useRef<HTMLDivElement>(null);

  return (
    <StaticQuery
      query={graphql`
        query Footer {
          storm: file(relativePath: { eq: "storm.png" }) {
            childImageSharp {
              fluid(quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }

          wp {
            audiences {
              edges {
                node {
                  title
                  uri
                }
              }
            }

            partners {
              edges {
                node {
                  title
                  partnerCustomFields {
                    logo {
                      sourceUrl
                      localFile {
                        childImageSharp {
                          fluid(quality: 80, maxWidth: 200) {
                            ...GatsbyImageSharpFluid_withWebp
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data: FooterQuery) => (
        <>
          <WavySection
            top="default"
            backgroundColor={backgroundColor || colors.white}
            style={{
              position: "relative",
              zIndex: 1,
              paddingTop: `${1.5 * ySpaceRem}rem`,
              paddingBottom: `calc(${waveClearance} + ${1.5 * ySpaceRem}rem)`,
              transition: "padding 0.25s ease",
              ...style,
            }}
          >
            <Center>
              <a id="storm" />

              <div ref={stormWrapperEl} style={{ overflow: "hidden" }}>
                {(() => {
                  const itemWidthRem = 25 + 0.5 * ySpaceRem;

                  const isWrapped =
                    stormWrapperEl.current &&
                    stormWrapperEl.current.getBoundingClientRect().width /
                      remInPx <
                      itemWidthRem * 2;

                  return (
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        margin: `-${0.5 * ySpaceRem}rem -${0.5 * xSpaceRem}rem`,
                      }}
                    >
                      <div
                        style={{
                          margin: `${0.5 * ySpaceRem}rem ${0.5 * xSpaceRem}rem`,
                          flexBasis: "25rem",
                          flexGrow: 1,
                        }}
                      >
                        <h3
                          style={{
                            fontWeight: 700,
                            marginBottom: `${0.5 * ySpaceRem}rem`,
                            fontFamily: fontStacks.cubano,
                            fontSize: "2.25rem",
                            lineHeight: `${2 * ySpaceRem}rem`,
                            color: colors.mediumBlue,
                          }}
                        >
                          Hallo, ik ben Storm.
                          <br /> Lekker weertje vandaag?
                        </h3>

                        {isWrapped && (
                          <div
                            style={{
                              position: "relative",
                              ...(stormImageWrapperEl.current && {
                                paddingBottom: (() =>
                                  `${ySpaceRem / 2 -
                                    ((stormImageWrapperEl.current.getBoundingClientRect()
                                      .height /
                                      remInPx) %
                                      (ySpaceRem / 2))}rem`)(),
                              }),
                              maxWidth: "25rem",
                              transition: "padding 0.25s ease",
                            }}
                          >
                            <div ref={stormImageWrapperEl}>
                              <Img
                                fluid={
                                  data.storm?.childImageSharp
                                    ?.fluid as FluidObject
                                }
                              />
                            </div>

                            <div
                              style={{
                                position: "absolute",
                                right: `${0.5 * xSpaceRem}rem`,
                                bottom: `${0.5 * ySpaceRem}rem`,
                                textAlign: "center",
                              }}
                            >
                              <p style={{ fontWeight: 700 }}>Storm</p>
                              <p>
                                Online
                                <br /> assistent
                              </p>
                            </div>
                          </div>
                        )}

                        <p style={{ marginBottom: `${ySpaceRem}rem` }}>
                          Wat merkt u van droogte, hitte en wateroverlast? Welke
                          effecten vindt u acceptabel en welke niet? Heeft u een
                          goed idee voor maatregelen om ons aan te passen aan de
                          effecten van het nieuwe klimaat?
                          <br /> Laat het weten aan onze online assistent Storm!
                          Hij legt u graag een aantal vragen voor.
                        </p>

                        <button
                          style={{
                            height: `${1.5 * ySpaceRem}rem`,
                            borderRadius: "1.5rem",
                            border: "none",
                            backgroundColor: colors.orange,
                            fontSize: "1rem",
                            fontWeight: 700,
                            fontFamily: fontStacks.univiaPro,
                            color: colors.white,
                            paddingLeft: `${0.5 * ySpaceRem}rem`,
                            paddingRight: `${0.5 * ySpaceRem}rem`,
                            marginBottom: `${ySpaceRem}rem`,
                          }}
                          onClick={() => {
                            // @ts-ignore
                            if (quriobot !== "undefined") quriobot.start();
                          }}
                        >
                          Chat met Storm &rarr;
                        </button>

                        <div style={{ overflow: "hidden" }}>
                          <ul
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              color: colors.black,
                              margin: `${-0.5 * ySpaceRem}rem ${-1 *
                                xSpaceRem}rem`,
                            }}
                          >
                            <li
                              style={{
                                margin: `${0.5 * ySpaceRem}rem ${xSpaceRem}rem`,
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <svg width={18} height={20} viewBox="0 0 18 20">
                                <g fill="none" fillRule="evenodd">
                                  <path d="M-151-3099h1440V651H-151z" />
                                  <path
                                    d="M9 .833c2.948.039 5.167 2.43 5.167 5.374 0 2.278-1.26 4.224-3.27 5.007 1.194.174 2.585.715 4.173 1.623a4.482 4.482 0 012.263 3.89v.773c-.001.92-.753 1.665-1.68 1.665L9 19.166H2.348c-.928 0-1.68-.746-1.68-1.666l-.001-.772c-.001-1.605.862-3.088 2.263-3.89 1.588-.909 2.98-1.45 4.173-1.624-2.01-.783-3.27-2.729-3.27-5.006C3.833 3.264 6.052.872 9 .834z"
                                    fill={colors.black}
                                  />
                                </g>
                              </svg>

                              <p
                                style={{
                                  marginLeft: `${0.5 * xSpaceRem}rem`,
                                }}
                              >
                                100% anoniem
                              </p>
                            </li>
                            <li
                              style={{
                                margin: `${0.5 * ySpaceRem}rem ${xSpaceRem}rem`,
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <svg width={10} height={16} viewBox="0 0 10 16">
                                <defs>
                                  <path
                                    id="privacy-policy__a"
                                    d="M5.833 4.27L11.011 10l-5.178 5.73 1.591 1.77 6.743-7.5-6.743-7.5z"
                                  />
                                </defs>
                                <g fill="none" fillRule="evenodd">
                                  <path d="M-315-3101h1440V649H-315z" />
                                  <use
                                    fill={colors.black}
                                    xlinkHref="#privacy-policy__a"
                                    transform="translate(-5 -2)"
                                  />
                                </g>
                              </svg>

                              <p
                                style={{
                                  marginLeft: `${0.5 * xSpaceRem}rem`,
                                }}
                              >
                                <Link to="/privacybeleid/">Privacybeleid</Link>
                              </p>
                            </li>
                            <li
                              style={{
                                margin: `${0.5 * ySpaceRem}rem ${xSpaceRem}rem`,
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <svg width={20} height={20} viewBox="0 0 20 20">
                                <defs>
                                  <path
                                    d="M12.779 12.098l3.738 2.219c.47.279.626.884.35 1.355a.976.976 0 01-1.335.35l-4.768-2.896V7.733a1.007 1.007 0 112.015 0v4.365zM12 20.028a7.814 7.814 0 004.038-1.105 7.785 7.785 0 002.885-2.885A7.814 7.814 0 0020.03 12a7.814 7.814 0 00-1.106-4.038 7.785 7.785 0 00-2.885-2.885A7.814 7.814 0 0012 3.97a7.814 7.814 0 00-4.038 1.106 7.785 7.785 0 00-2.885 2.885A7.814 7.814 0 003.97 12c0 1.442.369 2.788 1.106 4.038a7.785 7.785 0 002.885 2.885A7.814 7.814 0 0012 20.03zM12 2c1.827 0 3.518.457 5.072 1.37a9.478 9.478 0 013.558 3.558A9.835 9.835 0 0122 12a9.835 9.835 0 01-1.37 5.072 9.478 9.478 0 01-3.558 3.558A9.835 9.835 0 0112 22a9.835 9.835 0 01-5.072-1.37 9.657 9.657 0 01-3.558-3.582A9.79 9.79 0 012 12a9.79 9.79 0 011.37-5.048A9.842 9.842 0 016.952 3.37 9.79 9.79 0 0112 2z"
                                    id="duration__a"
                                  />
                                </defs>
                                <g fill="none" fillRule="evenodd">
                                  <path d="M-468-3099H972V651H-468z" />
                                  <use
                                    fill={colors.black}
                                    xlinkHref="#duration__a"
                                    transform="translate(-2 -2)"
                                  />
                                </g>
                              </svg>

                              <p
                                style={{
                                  marginLeft: `${0.5 * xSpaceRem}rem`,
                                }}
                              >
                                ± 10 min.
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                      {/* </ScrollableAnchor> */}

                      {!isWrapped && (
                        <div
                          style={{
                            position: "relative",
                            margin: `${0.5 * ySpaceRem}rem ${0.5 *
                              xSpaceRem}rem`,
                            flexBasis: "25rem",
                            flexGrow: 1,
                            ...(stormImageWrapperEl.current && {
                              paddingBottom: (() =>
                                `${ySpaceRem / 2 -
                                  ((stormImageWrapperEl.current.getBoundingClientRect()
                                    .height /
                                    remInPx) %
                                    (ySpaceRem / 2))}rem`)(),
                            }),
                            transition: "padding 0.25s ease",
                          }}
                        >
                          <div ref={stormImageWrapperEl}>
                            <Img
                              fluid={
                                data.storm?.childImageSharp
                                  ?.fluid as FluidObject
                              }
                            />
                          </div>

                          <div
                            style={{
                              position: "absolute",
                              right: `${xSpaceRem}rem`,
                              bottom: `${2 * ySpaceRem}rem`,
                              textAlign: "center",
                            }}
                          >
                            <p style={{ fontWeight: 700 }}>Storm</p>
                            <p>
                              Online
                              <br /> assistent
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })()}
              </div>
            </Center>
          </WavySection>
          <WavySection
            backgroundColor={colors.mediumBlue}
            top="flipped"
            style={{
              color: colors.white,
              paddingTop: `${ySpaceRem}rem`,
              paddingBottom: `${1.5 * ySpaceRem}rem`,
            }}
          >
            <Center>
              <div style={{ overflow: "hidden" }}>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    margin: `-${0.5 * ySpaceRem}rem -${0.5 * xSpaceRem}rem`,
                  }}
                >
                  <div
                    style={{
                      margin: `${0.5 * ySpaceRem}rem ${0.5 * xSpaceRem}rem`,
                      flexGrow: 1,
                    }}
                  >
                    <h3
                      style={{
                        fontWeight: 700,
                        marginBottom: `${0.5 * ySpaceRem}rem`,
                        fontFamily: fontStacks.cubano,
                        fontSize: "1.75rem",
                        lineHeight: `${1.5 * ySpaceRem}rem`,
                      }}
                    >
                      Navigatie
                    </h3>
                    <ul>
                      <li>
                        <Link to="/klimaatthemas/hitte/">Atlas</Link>
                      </li>

                      <li>
                        <Link to="/over-ons/">Deelnemende partners</Link>
                      </li>

                      <li>
                        <Link to="/verhalen/">Klimaatverhalen</Link>
                      </li>

                      <li>
                        <Link to="/tips/">Tips</Link>
                      </li>

                      <li>
                        <Link to="/veelgestelde-vragen/">
                          Veelgestelde vragen
                        </Link>
                      </li>

                      <li>
                        <Link to="/disclaimer/">Disclaimer</Link>
                      </li>

                      <li>
                        <Link to="/privacybeleid/">Privacybeleid</Link>
                      </li>

                      <li>
                        <Link to="/toegankelijkheid/">Toegankelijkheid</Link>
                      </li>

                      <li>
                        <Link to="/toolbox/">Toolbox</Link>
                      </li>

                      <li>
                        <Link to="/sitemap/">Sitemap</Link>
                      </li>
                    </ul>
                  </div>

                  <div
                    style={{
                      margin: `${0.5 * ySpaceRem}rem ${0.5 * xSpaceRem}rem`,
                      flexGrow: 1,
                    }}
                  >
                    <h3
                      style={{
                        fontWeight: 700,
                        marginBottom: `${0.5 * ySpaceRem}rem`,
                        fontFamily: fontStacks.cubano,
                        fontSize: "1.75rem",
                        lineHeight: `${1.5 * ySpaceRem}rem`,
                      }}
                    >
                      Info voor
                    </h3>
                    <ul>
                      {data.wp.audiences?.edges?.map(
                        (edge, i) =>
                          edge?.node && (
                            <li key={i}>
                              <Link to={`/${edge.node.uri}`}>
                                {edge.node.title}
                              </Link>
                            </li>
                          )
                      )}
                    </ul>
                  </div>

                  <div
                    style={{
                      margin: `${0.5 * ySpaceRem}rem ${0.5 * xSpaceRem}rem`,
                      flexBasis: "25rem",
                      flexGrow: 1,
                    }}
                  >
                    <h3
                      style={{
                        fontWeight: 700,
                        marginBottom: `${0.5 * ySpaceRem}rem`,
                        fontFamily: fontStacks.cubano,
                        fontSize: "1.75rem",
                        lineHeight: `${1.5 * ySpaceRem}rem`,
                      }}
                    >
                      Contact
                    </h3>
                    <p>
                      Heeft u vragen over de klimaateffecten of hulp nodig? Neem
                      gerust contact op met{" "}
                      <Link
                        to="/over-ons/"
                        style={{ textDecoration: "underline" }}
                      >
                        uw eigen gemeente
                      </Link>
                      .
                    </p>
                  </div>
                </div>
              </div>
            </Center>
          </WavySection>
          <div
            style={{
              paddingTop: `${ySpaceRem}rem`,
              paddingBottom: `${ySpaceRem}rem`,
            }}
          >
            <Center>
              <Link
                to="/over-ons/"
                title="Lees meer over alle initiatiefnemers van dit project."
              >
                <div
                  style={{
                    overflow: "hidden",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      margin: `${-0.5 * ySpaceRem}rem`,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {data.wp.partners?.edges?.map((edge, i) => (
                      <div
                        key={i}
                        style={{
                          flexBasis: `${4.5 * xSpaceRem}rem`,
                          margin: `${0.5 * ySpaceRem}rem`,
                        }}
                      >
                        <Img
                          alt={edge?.node?.title || ""}
                          fluid={
                            edge?.node?.partnerCustomFields?.logo?.localFile
                              ?.childImageSharp?.fluid as FluidObject
                          }
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </Link>
            </Center>
          </div>
        </>
      )}
    />
  );
};
